import React from 'react'

const AboutPage = () => {
    return (
        <div>
            <h1>
                about page
            </h1>
            <p>Under Construction</p>
        </div>
    )
}

export default AboutPage